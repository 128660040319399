@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
}

html {
  margin: 0;
  height: 100%;
}

#root > div {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f4f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
